import React, { useState } from 'react';
import { logo, icon1, icon2, icon3, icon4, icon7 } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const products = [
  { id: 1, image: '🌐',  title: "Vebsaytların hazırlanması", description: "Profesional və sürətli vebsayt hazırlama xidmətləri" },
  { id: 2, image: '🖥️', title: "Domen və hostinq satışı", description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri" },
  { id: 3, image: '⚙️', title: "Google SEO", description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın" },
  { id: 4, image: '🛠️', title: "Vebsaytlara texniki dəstək", description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri" },
  { id: 5, image: '📧', title: "Korporativ poçt", description: "Güvənli və professional korporativ e-poçt həlləri" },
  { id: 6, image: '💳', title: "Ödəmə sistemlərinin inteqrasiyası", description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası" },
];

const Header = () => {

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  const { t } = useTranslation();


  return (
    <>
      <header className="bg-[#388E3C] hidden md:block">
        <div className="max-w-containerSm md:max-w-container mx-auto h-[60px] flex justify-between">
          <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu} aria-label="Toggle menu">
            <i className="fa-solid fa-bars text-[24px] text-[black]"></i>
            {isHamburgerMenuOpen && (
              <div className={`fixed inset-y-0 bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
                <div className="flex">
                  <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu} aria-label="Close menu">
                    <i className="fa-solid fa-xmark text-[32px] text-[black]"></i>
                  </button>
                </div>
                <nav className="flex flex-col items-start p-8 w-full">
                  <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
                    <div className="gap-4 max-w-containerSm flex flex-col">
                      <a href="/" aria-label="Ana səhifə"><img src={logo} alt="Logo" className="w-[109px] mb-5" /></a>
                      <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#587257]' : 'text-[black]'}`} href="/">{t("Ana səhifə")}</a>
                      <a href="/why-us" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Niyə biz?")}</a>
                      <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Xidmətlərimiz")}</a>
                      <a href="/portfolio" className={`text-base text-left font-normal ${isActiveLink('/blogs') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Portfolio")}</a>
                      <a href="/faq" className={`text-base text-left font-normal ${isActiveLink('/kampaniya') ? 'text-[#587257]' : 'text-[black]'}`}>{t("FAQ")}</a>
                      <a href="/blogs" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Bloq")}</a>
                      <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
                    </div>
                  </div>
                </nav>
              </div>
            )}
          </button>

          <div className="flex items-center gap-5 text-white">
            <div className="flex gap-2">
              <img className="h-6 w-6" src={icon4} alt="Telefon ikonu" />
              <p>+994 51 683 42 18</p>
            </div>
            <div className="flex gap-2">
              <img className="h-6 w-6" src={icon7} alt="E-mail ikonu" />
              <p>info@prosayt.az</p>
            </div>
          </div>

          <div className="flex items-center justify-end relative ">
            <div className="bg-opacity-50 z-10 flex justify-center items-center">
              <div >
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t("Axtar...")}
                  className="p-2 rounded-[9px] w-[400px] text-black"
                  aria-label="Search"
                />
              </div>
            </div>
          </div>

          <div className="md:flex items-center w-[300px] hidden justify-end">
            <nav>
              <ul className="flex justify-between text-[black] gap-3">
                <li>
                  <a href="https://api.whatsapp.com/send/?phone=994516834218&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                    <img className="h-6 w-6" src={icon1} alt="WhatsApp ikonu" />
                  </a>
                </li>
                <li>
                 <a href="https://www.linkedin.com/company/prosaytmmc" target="_blank" rel="noopener noreferrer">
                               <img className="w-6 h-6" src={icon2} alt={t("LinkedIn İkonu")} />
                             </a>
                </li>
                <li>
                   <a href="https://www.instagram.com/prosayt.az" target="_blank" rel="noopener noreferrer">
                                <img className="w-6 h-6" src={icon3} alt={t("Instagram İkonu")} />
                              </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {searchQuery && (
          <div className="absolute top-0 left-0 w-full bg-white z-50 mt-[86px] grid grid-cols-3 px-10 gap-5">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((item) => (
                <a href={`/services`} key={item.id} aria-label={item.title}>
                  <div className="rounded-[20px] py-4 w-[400px] h-[220px] px-3 shadow bg-white">
                    <div className="h-[50px] w-[50px] mx-auto text-4xl" role="img" aria-label={item.title}>
                      {item.image}
                    </div>
                    <div>
                      <p className="mt-[12px] text-[22px] font-medium text-center">{t(item.title)}</p>
                      <p className="mt-[12px] mb-[32px] text-center">
                        {item.description.length > 80 ? item.description.slice(0, 80) + '...' : t(item.description)}
                      </p>
                    </div>
                  </div>
                </a>
              ))
            ) : (
              <p className="text-white text-center">{t("No results found")}</p>
            )}
          </div>
        )}
      </header>
    </>
  );
};

export default Header;

