import React from 'react';
import { useTranslation } from 'react-i18next';

const Awards = () => {
  const { t } = useTranslation();

  return (
    <div className='mt-[64px]'>
      <div className="md:max-w-container max-w-containerSm mx-auto justify-between flex md:flex-row flex-col items-center gap-5">
        
        {/* First Award Section */}
        <div className='bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
          <h2 className='text-[32px] font-semibold'>{t("7 illik")}</h2>
          <p className='text-[18px]'>{t("Təcrübə")}</p>
        </div>
        
        {/* Second Award Section */}
        <div className='bg-[#FB8C00] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
          <h2 className='text-[32px] font-semibold'>300 +</h2>
          <p className='text-[18px]'>{t("Hazırlanan veb sayt")}</p>
        </div>
        
        {/* Third Award Section */}
        <div className='bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
          <h2 className='text-[32px] font-semibold'>100 %</h2>
          <p className='text-[18px]'>{t("Zamanında təhvil")}</p>
        </div> 
      </div>
    </div>
  );
};

export default Awards;
