import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const servicesData = [
  { id: 1, image: '🌐', title: "Vebsaytların hazırlanması", description: "Profesional və sürətli vebsayt hazırlama xidmətləri" },
  { id: 2, image: '🖥️', title: "Domen və hostinq satışı", description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri" },
  { id: 3, image: '⚙️', title: "Google SEO", description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın" },
  { id: 4, image: '🛠️', title: "Vebsaytlara texniki dəstək", description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri" },
  { id: 5, image: '📧', title: "Korporativ poçt", description: "Güvənli və professional korporativ e-poçt həlləri" },
  { id: 6, image: '💳', title: "Ödəmə sistemlərinin inteqrasiyası", description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası" },
];

const Services = () => {

  const { t } = useTranslation();

  return (
    <>
      {/* Masaüstü versiyonu */}
      <div className="max-w-container mx-auto mt-[64px] md:mt-[104px] hidden md:block">
        <div className="flex items-center justify-between">
          <h2 className="text-[46px] font-medium">{t("Xidmətlərimiz")}</h2> {/* h2 olarak değiştirildi */}
          <a href='/services' aria-label={t("Hamısına bax")}>
            <button className='h-[51px] w-[164px] rounded-[12px] bg-[#FB8C00] text-white'>{t("Hamısına bax")}</button>
          </a>
        </div>
        <div className="grid grid-cols-3 mt-10 gap-10">
          {servicesData.map((item) => (
            <div key={item.id} className="rounded-[20px] py-4 w-[400px] h-[220px] px-3 shadow bg-white">
              <div className="h-[50px] w-[50px] mx-auto text-4xl" aria-label={item.title}>{item.image}</div>
              <div>
                <h3 className="mt-[12px] text-[22px] font-medium text-center">{t(item.title)}</h3> {/* h3 olarak değiştirildi */}
                <p className="mt-[12px] mb-[32px] text-center">
                  {item.description.length > 80 ? item.description.slice(0, 80) + '...' : t(item.description)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobil versiyon */}
      <div className="max-w-containerSm mx-auto mt-[64px] md:mt-[104px] block md:hidden">
        <p className="text-3xl font-medium text-center mb-8">{t("Xidmətlərimiz")}</p>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {servicesData.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="rounded-[20px] py-4 px-3 shadow bg-white">
                <div className="h-[50px] w-[50px] mx-auto text-4xl" aria-label={slide.title}>{slide.image}</div>
                <div>
                  <h3 className="mt-[12px] text-[22px] font-medium text-center">{t(slide.title)}</h3> {/* h3 olarak değiştirildi */}
                  <p className="mt-[12px] mb-[32px] text-center">
                    {slide.description.length > 60 ? slide.description.slice(0, 60) + '...' : t(slide.description)}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <a href='/services' aria-label={t("Hamısına bax")}>
          <button className='h-[51px] w-full md:w-[164px] rounded-[12px] mt-8 bg-[#FB8C00] text-white'>{t("Hamısına bax")}</button>
        </a>
      </div>
    </>
  );
};

export default Services;
