import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import App from "./App";

const Index = () => {
  return (
    <HelmetProvider>
      <App />
    </HelmetProvider>
  ); 
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);
