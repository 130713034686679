import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { sosial1, sosial2, sosial3, mailOrange, phoneOrange } from '../../assets/images';

const Contact = () => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");
  const [clientWish, setclientWish] = useState("");

  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage, wish: clientWish };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
       await axios.post("https://www.prosayt.az/prosayt/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      setclientWish('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };

  return (
    <>
      <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">

        <nav aria-label="Breadcrumb" className="mb-4">
        <p>{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`} /> {t("Əlaqə")}</p>
      </nav>

        <section className="md:flex justify-between gap-10 mt-[24px] md:mt-[56px]">
          <article className='md:w-[745px] md:h-[563px] bg-[white] rounded-[24px] px-6 py-4 box-border '>
            <h2 className='text-3xl md:text-[32px] font-medium md:mt-6'>{t("Bir Sualınız Var?")}</h2>
            <h3 className='text-3xl md:text-[32px] font-medium mb-8 md:mb-0 md:mt-6'>{t("Cavabımız Hazırdır")}</h3>

            <form onSubmit={handleSubmitContact} className='md:mt-6'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div>
                  <label htmlFor="name" className="sr-only">{t("Ad, soyad *")}</label>
                  <input
                    onChange={(event) => setclientName(event.target.value)}
                    value={clientName}
                    required
                    placeholder={t("Ad, soyad *")}
                    type="text"
                    id="name"
                    className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="sr-only">{t("Email *")}</label>
                  <input
                    onChange={(event) => setclientEmail(event.target.value)}
                    value={clientEmail}
                    required
                    placeholder={t("Email *")}
                    type="email"
                    id="email"
                    className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="sr-only">{t("Telefon *")}</label>
                  <input
                    onChange={(event) => setclientPhone(event.target.value)}
                    value={clientPhone}
                    required
                    placeholder={t("Telefon *")}
                    type="number"
                    id="phone"
                    className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="message" className="sr-only">{t("Sizə necə kömək edə bilərik? *")}</label>
                <textarea
                  id="message"
                  rows="4"
                  onChange={(event) => setclientWish(event.target.value)}
                  value={clientWish}
                  placeholder={t("Sizə necə kömək edə bilərik? *")}
                  className="bg-[white] h-36 pl-3 pr-3 pt-3 block w-full border mt-6 border-[#dddddd] rounded-[5px] shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                ></textarea>
              </div>

              <div className="flex justify-end">
                <button type="submit" className="w-[148px] h-10 p-2.5 bg-[#FB8C00] rounded-xl justify-center items-center mt-6 gap-2.5 inline-flex text-[white]">
                  {t("Göndər")}
                </button>
              </div>
            </form>
          </article>

          <aside className='md:w-[387px] mt-10 md:mt-0'>
            <ul className='space-y-2 md:space-y-4 mt-5'>
              <li className="text-[#FB8C00] text-xl">
                <a href="mailto:info@prosayt.az" className="hover:underline flex">
                  <img className='h-[30px] w-[30px] mr-2' src={mailOrange} alt="E-posta simgesi" />
                  {t("E-poçt")}
                </a>
              </li>
              <li className="text-xl">info@prosayt.az</li>

              <li className="text-[#FB8C00] text-xl">
                <a href="tel:+994516834218" className="hover:underline flex">
                  <img className='h-[30px] w-[30px] mr-2' src={phoneOrange} alt="Telefon simgesi" />
                  {t("Əlaqə nömrəsi")}
                </a>
              </li>
              <li className="text-xl">+994 50 683 42 18</li>
            </ul>

            <div className='flex gap-3 mt-[20px] md:mt-[65px]'>
              <div>
                <a href='https://api.whatsapp.com/send/?phone=994516834218&text&type=phone_number&app_absent=0' target="_blank" rel="noopener noreferrer">
                  <img className='w-10 h-10' src={sosial1} alt="WhatsApp ikonu" />
                </a>
              </div>
              <div>
                 <a href="https://www.instagram.com/prosayt.az" target="_blank" rel="noopener noreferrer">
                              <img className="w-10 h-10" src={sosial2} alt={t("Instagram İkonu")} />
                            </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/company/prosaytmmc" target="_blank" rel="noopener noreferrer">
                  <img className="w-10 h-10" src={sosial3} alt={t("LinkedIn İkonu")} />
                  </a>
              </div>
            </div>
          </aside>
        </section>

        <section className='mt-10 md:mt-20'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.859337037306!2d49.8414952!3d40.3763893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dc8c7cb8537%3A0xae4ef10fc5fafbf0!2sProsayt.az!5e0!3m2!1saz!2saz!4v1728198206019!5m2!1saz!2saz"
            className="w-full h-48 sm:h-96 md:h-64 lg:h-72 xl:h-96 max-w-full"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps Location"
          ></iframe>
        </section>
      </div>
    </>
  );
}

export default Contact;
