import React from 'react';
import { logoAbout } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="md:max-w-container max-w-containerSm mx-auto mt-[64px] md:mt-[104px] md:flex justify-between">
      
      {/* Image Section */}
      <div className="flex flex-col justify-center md:w-[479px] rounded-[24px]">
        <img
          className="rounded-xl"
          src={logoAbout}
          alt={t('Prosayt Logo')}  // SEO için uygun alt metin
        />
      </div>
      
      {/* Text Section */}
      <div className="md:w-[745px] bg-white rounded-[24px] py-4 px-6 mt-7 md:mt-0">
        <h2 className="md:text-[40px] md:leading-[50px] text-xl font-medium">{t("IT Sektoru Lideri - PROSAYT MMC sizi qarşılayır !")}</h2>  {/* H2 başlık etiketi */}
        <p className="mt-[18px] md:text-[20px] text-gray-700">
          {t("Prosayt şirkəti olaraq, 7 illik təcrübəmizlə yüksək keyfiyyətli və sürətli vebsaytların hazırlanması sahəsində fəaliyyət göstəririk. Müxtəlif sahələr üzrə 200-dən çox uğurla tamamlanmış layihəmiz mövcuddur. Biz müştərilərimizə yalnız vebsayt yaradılması deyil, eyni zamanda domen və hostinq satışı, korporativ poçt xidmətləri və texniki dəstək kimi geniş çeşidli xidmətlər də təklif edirik. Məqsədimiz, hər bir müştərimiz üçün fərdi ehtiyacları nəzərə alaraq, mükəmməl həllər təqdim etmək və onların onlayn uğurunu təmin etməkdir.")}  
        </p>
        
        {/* Meta description ekleyin */}
        <p className="hidden">
          {t("Prosayt, 7 yıllık deneyimi ile yüksek kaliteli ve hızlı web sitesi tasarımları sunmaktadır. Web geliştirme, hosting, alan adı satışları ve teknik destek gibi hizmetler sunuyoruz.")}
        </p>
        
        <a href="/why-us">
          <button className="h-[51px] w-full md:w-[164px] rounded-[12px] bg-[#FB8C00] text-white mt-[32px]">
            {t("Daha ətraflı")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default About;

