import React, { useEffect, useState } from 'react';
import { arbFlag, azFlag, engFlag, fraFlag, logo, rusFlag} from '../../assets/images';
import { useLocation } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import arbTranslation from "../i18n/arb/translation.json";
import fraTranslation from "../i18n/fra/translation.json";


const languageOptions = [
  { value: "en", label: "EN", flag: engFlag },
  { value: "az", label: "AZ", flag: azFlag },
  { value: "rus", label: "RU", flag: rusFlag },
  { value: "fra", label: "FRA", flag: fraFlag },
  { value: "arb", label: "ARB", flag: arbFlag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
      arb: { translation: arbTranslation },
      fra: { translation: fraTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});



const BelowHeader = () => {


  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  const { t } = useTranslation();
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);


  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };




  

  return (
    <>
       <div className='shadow bg-white'>
       <div className='max-w-containerSm md:max-w-container mx-auto h-[60px] flex flex-row-reverse md:flex-row justify-between'>
        <div className='flex flex-row-reverse md:hidden'>
        <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px] text-[black]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0  bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px] text-[black]"></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[109px] mb-5" /></a>
                      <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#587257]' : 'text-[black]'}`} href="/"> {t("Ana səhifə")}</a>
                      <a href="/why-us" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Niyə biz?")}</a>
                      <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Xidmətlərimiz")}</a>
                      <a href="/portfolio" className={`text-base text-left font-normal ${isActiveLink('/blogs') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Portfolio")}</a>
                      <a href="/faq" className={`text-base text-left font-normal ${isActiveLink('/kampaniya') ? 'text-[#587257]' : 'text-[black]'}`}>{t("FAQ")}</a>
                      <a href="/blogs" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Bloq")}</a>
                      <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
    </div>

          </div>
          </div>
        </div>
      )} 
      </button>
         <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap md:hidden">
            
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 px-2 py-2 rounded-full"
            >
              <img alt="flag" className='w-6 h-6' src={selectedLanguage.flag}></img>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69222 7.09327C3.91686 6.83119 4.31143 6.80084 4.5735 7.02548L10.0001 11.6768L15.4267 7.02548C15.6888 6.80084 16.0833 6.83119 16.308 7.09327C16.5326 7.35535 16.5022 7.74991 16.2402 7.97455L10.4068 12.9745C10.1728 13.1752 9.8274 13.1752 9.59335 12.9745L3.76001 7.97455C3.49794 7.74991 3.46759 7.35535 3.69222 7.09327Z" fill="#B8B8B8"/>
            </svg>
            </button>
            <button className="block md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
            {isHamburgerMenuOpen && (
            <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
                 <div className="flex">
            <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
            <i className="fa-solid fa-xmark text-[32px]"></i>
            </button>
            
                 </div>
            <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
            <a href='/'> <img src={logo} alt="Logo" className="h-[32px] mb-5" /></a>
            <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#587257]' : 'text-[black]'}`} href="/"> {t("Ana səhifə")}</a>
                      <a href="/why-us" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Niyə biz?")}</a>
                      <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Xidmətlərimiz")}</a>
                      <a href="/portfolio" className={`text-base text-left font-normal ${isActiveLink('/blogs') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Portfolio")}</a>
                      <a href="/faq" className={`text-base text-left font-normal ${isActiveLink('/kampaniya') ? 'text-[#587257]' : 'text-[black]'}`}>{t("FAQ")}</a>
                      <a href="/blogs" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Bloq")}</a>
                      <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#587257]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
            
            </div>
            
            </div>
            </div>
            </div>
            )} 
            </button>
            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-16 rounded-md shadow-lg bg-[white] ring-1 ring-black ring-opacity-5 z-50 border-[1px] border-black">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 text-[#b7b7b7] py-2 w-full text-left hover:bg-[#EDEDED]"
                    >
                      <img alt="flag" className='w-6 h-6' src={language.flag}></img>
                    </button>
                  ))}
                </div>
              </div>
            )}
            </div>
        </div>
            

  
     <div className='relative flex items-center '>
     <a href='/'>
      <img alt="logo" className='h-[32px]' src={logo}></img>
     </a>
    </div>






          <div className='md:flex items-center w-[1040px] hidden justify-end'>
          <nav>
  <ul className="flex justify-between text-[black]">
    <a className={`mr-[40px] ${isActiveLink('/') ? 'text-[#FB8C00] ' : 'text-[black]'}`}  href="/">
      <li>{t("Ana səhifə")}</li>
    </a>
    <a className={`mr-[40px] ${isActiveLink('/why-us') ? 'text-[#FB8C00] ' : 'text-[black]'}`} href="/why-us">
      <li>{t("Niyə biz?")}</li>
    </a>

    <a className={`mr-[40px] ${isActiveLink('/services') ? 'text-[#FB8C00] ' : 'text-[black]'}`} href="/services">
      <li>{t("Xidmətlərimiz")}</li>
    </a>
    <a className={`mr-[40px] ${isActiveLink('/portfolio') ? 'text-[#FB8C00]' : 'text-[black]'}`} href="/portfolio">
      <li>{t("Portfolio")}</li>
    </a>
    <a className={`mr-[40px] ${isActiveLink('/faq') ? 'text-[#FB8C00]' : 'text-[black]'}`} href="/faq">
      <li>{t("FAQ")}</li>
    </a>
    <a className={`mr-[40px] ${isActiveLink('/blogs') ? 'text-[#FB8C00] ' : 'text-[black]'}`} href="/blogs">
      <li>{t("Bloq")}</li>
    </a>
    <a className={`mr-[40px] ${isActiveLink('/contact') ? 'text-[#FB8C00]' : 'text-[black]'}`} href="/contact">
      <li>{t("Əlaqə")}</li>
    </a>
  </ul>

</nav>
<div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">

<button
  onClick={toggleDropdown}
  className="flex items-center space-x-2 px-2 py-2rounded-full"
>
  <img alt="flag" className='w-6 h-6' src={selectedLanguage.flag}></img>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.69222 7.09327C3.91686 6.83119 4.31143 6.80084 4.5735 7.02548L10.0001 11.6768L15.4267 7.02548C15.6888 6.80084 16.0833 6.83119 16.308 7.09327C16.5326 7.35535 16.5022 7.74991 16.2402 7.97455L10.4068 12.9745C10.1728 13.1752 9.8274 13.1752 9.59335 12.9745L3.76001 7.97455C3.49794 7.74991 3.46759 7.35535 3.69222 7.09327Z" fill="#B8B8B8"/>
</svg>
</button>
<button className="block md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
<i className="fa-solid fa-bars text-[24px]"></i>
{isHamburgerMenuOpen && (
<div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
     <div className="flex">
     <div className="absolute top-8 right-20">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
</div>
<button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
<i className="fa-solid fa-xmark text-[32px]"></i>
</button>

     </div>
<div className="flex flex-col items-start p-8 w-full ">
<div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
<div className="gap-4 max-w-containerSm flex flex-col"> 
<a href='/'> <img src={logo} alt="Logo" className="h-[90px]" /></a>
<a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#0D4B2C] ' : 'text-[black]'}`} href="/"> {t("Ana səhifə")}</a>
  <a href="/why-us" className={`text-base text-left font-normal ${isActiveLink('/why-us') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Niyə biz?")}</a>
  <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/services') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Xidmətlərimiz")}</a>
  <a href="/portfolio" className={`text-base text-left  font-normal ${isActiveLink('/portfolio') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Portfolio")}</a>
  <a href="/faq" className={`text-base text-left font-normal ${isActiveLink('/faq') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("FAQ")}</a>
  <a href="/blogs" className={`text-base text-left font-normal ${isActiveLink('/blogs') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Bloq")}</a>
  <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
</div>

</div>
</div>
</div>
)} 
</button>
{isOpen && (
  <div className="absolute right-0 top-10 mt-2 w-16 rounded-md shadow-lg bg-[white] ring-1 ring-black ring-opacity-5 z-50 border-[1px] border-black">
    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      {languageOptions.map((language) => (
        <button
          key={language.value}
          onClick={() => handleLanguageChange(language)}
          className="flex items-center space-x-2 px-4 text-[#b7b7b7] py-2 w-full text-left hover:bg-[#EDEDED]"
        >
          <img alt="flag" className='w-6 h-6' src={language.flag}></img>
        </button>
      ))}
    </div>
  </div>
)}
</div>

          </div>
      </div>
       </div>
    </>
  );
};

export default BelowHeader;


