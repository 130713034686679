import React from 'react';
import { partners1, partners2, partners3, partners4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Partners = () => {
  const { t } = useTranslation();

  // Partners array with images
  const partners = [
    { id: 1, image: partners1, alt: t("Partner 1") },
    { id: 2, image: partners2, alt: t("Partner 2") },
    { id: 3, image: partners3, alt: t("Partner 3") },
    { id: 4, image: partners4, alt: t("Partner 4") },
  ];

  return (
    <div className="md:max-w-container md:mt-[104px] mt-[64px] max-w-containerSm mx-auto">
      <h2 className="text-black text-3xl md:text-[46px] font-medium text-center mb-8 md:mb-0">
        {t("Partnyorlarımız")}
      </h2>

      {/* Desktop view */}
      <div className="hidden mt-[56px] md:gap-[124px] md:flex">
        {partners.map((partner, index) => (
          <div key={index}>
            <img
              className="h-[80px] mx-auto mt-8 md:mx-0 md:mt-0"
              src={partner.image}
              alt={partner.alt}
              title={partner.alt} // Erişilebilirlik ve SEO için title ekledik
            />
          </div>
        ))}
      </div>

      {/* Mobile view with swiper */}
      <div className="block md:hidden">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {partners.map((partner, index) => (
            <SwiperSlide key={index}>
              <a href={`/partners/${partner.id}`} title={partner.alt}>
                <div className="flex justify-center items-center">
                  <img src={partner.image} alt={partner.alt} title={partner.alt} />
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Partners;
