import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Helmet'ı burada kullanalım
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import BelowHeader from "./Components/BelowHeader/BelowHeader";
import WhyUs from "./Pages/WhyUs/WhyUs";
import Services from "./Pages/Services/Services";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Faq from "./Pages/Faq/Faq";
import Blogs from "./Pages/Blogs/Blogs";
import BlogsDetail from "./Pages/BlogsDetail/BlogsDetail";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { avatar } from "./assets/images";

const Layout = () => {
  return (
    <div>
      <Header />
      <BelowHeader />
      <Outlet /> 
      <Footer />
    </div>
  );
};

// Sayfa bazlı meta etiketlerini ayarlamak için `Helmet`'i kullanıyoruz.
const router = createBrowserRouter([
  {
    path: "/", 
    element: <Layout />,
    children: [
      {
        path: "/",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Ana Səhifə</title>
              <meta
                name="description"
                content="Prosayt müasir və son texnologiyalara uyğun veb saytlar hazırlayan peşəkar bir veb agentliyidir."
              />
            </Helmet>
            <Home />
          </>
        ),
      },
      {
        path: "/why-us",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Niyə Biz?</title>
              <meta
                name="description"
                content="Şirkətimiz ilə işləməyin üstünlüklərini kəşf edin."
              />
            </Helmet>
            <WhyUs />
          </>
        ),
      },
      {
        path: "/services",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Xidmətlərimiz</title>
              <meta
                name="description"
                content="Veb saytların hazırlanması, domen və hostinq xidmətləri təqdim edirik."
              />
            </Helmet>
            <Services />
          </>
        ),
      },
      {
        path: "/portfolio",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Portfolio</title>
              <meta
                name="description"
                content="Hazırladığımız layihələrlə portfolioda tanış olun."
              />
            </Helmet>
            <Portfolio />
          </>
        ),
      },
      {
        path: "/faq",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Tez-tez Verilən Suallar</title>
              <meta
                name="description"
                content="Veb saytlar və xidmətlərimiz ilə bağlı tez-tez verilən suallar."
              />
            </Helmet>
            <Faq />
          </>
        ),
      },
      {
        path: "/blogs",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Bloqlar</title>
              <meta
                name="description"
                content="Veb saytlar, SEO və veb dizayn mövzularında bloq yazılarımızı oxuyun."
              />
            </Helmet>
            <Blogs />
          </>
        ),
      },
      {
        path: "/blogs/:id",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Bloq Detalı</title>
              <meta
                name="description"
                content="Veb saytlar, SEO və veb dizayn haqqında daha ətraflı blog yazıları."
              />
            </Helmet>
            <BlogsDetail />
          </>
        ),
      },
      {
        path: "/contact",
        element: (
          <>
            <Helmet>
              <title>Prosayt MMC | Əlaqə</title>
              <meta
                name="description"
                content="Bizimlə əlaqə saxlamaq üçün formu doldurun."
              />
            </Helmet>
            <Contact />
          </>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <div className="font-bodyFont bg-[#FBFBFB]">
      <FloatingWhatsApp
        phoneNumber="+994516834218"
        accountName="PROSAYT"
        statusMessage=""
        chatMessage="Salam, sizə necə kömək edə bilərik?"
        placeholder="Mesaj yazın..."
        avatar={avatar}
        messageDelay="1"
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
