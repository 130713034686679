import React from 'react';
import { logoAbout, why1, why2, why3, why4, why5, why6 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const WhyUs = () => {
  const { t } = useTranslation();

  const opportunitiesData = [
    { id: 1, image: why1, title: "Peşəkar Komanda", description: "Təcrübəli və ixtisaslı mütəxəssislərlə layihələrinizi reallaşdırırıq." },
    { id: 2, image: why2, title: "Kreativ Yanaşma", description: "Hər layihəyə fərqli və yenilikçi baxış bucağı gətiririk." },
    { id: 3, image: why3, title: "Texniki Dəstək", description: "Xidmət sonrası tam texniki dəstək ilə hər zaman yanınızdayıq." },
    { id: 4, image: why4, title: "Keyfiyyət və Etibarlılıq", description: "Yüksək keyfiyyətli və etibarlı həllər təqdim edirik." },
    { id: 5, image: why5, title: "Münasib Qiymətlər", description: "Büdcənizə uyğun və keyfiyyətli xidmətlər təklif edirik." },
    { id: 6, image: why6, title: "Müasir Texnologiyalar", description: "Layihələrinizdə ən son texnologiyalardan istifadə edirik." },
  ];

  return (
    <>

      <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
        <p>{t("Ana səhifə")} <i className="fas fa-chevron-right text-black ml-3 mr-3" /> {t("Niyə biz ?")}</p>

        <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px] md:flex justify-between">
          <div className="flex flex-col justify-center md:w-[479px] rounded-[24px]">
            <img className="rounded-[12px]" src={logoAbout} alt={t("Prosayt MMC Logo")} loading="lazy" />
          </div>
          <div className="md:w-[745px] bg-white rounded-[24px] py-4 px-6 mt-6 md:mt-0">
            <h2 className="md:text-[30px] md:leading-[50px] text-xl font-medium">{t("IT Sektoru Lideri - PROSAYT MMC sizi qarşılayır !")}</h2>
            <p className="mt-[18px] md:text-[20px]">{t("Prosayt şirkəti olaraq, 7 illik təcrübəmizlə yüksək keyfiyyətli və sürətli vebsaytların hazırlanması sahəsində fəaliyyət göstəririk. Müxtəlif sahələr üzrə 200-dən çox uğurla tamamlanmış layihəmiz mövcuddur. Biz müştərilərimizə yalnız vebsayt yaradılması deyil, eyni zamanda domen və hostinq satışı, korporativ poçt xidmətləri və texniki dəstək kimi geniş çeşidli xidmətlər də təklif edirik. Məqsədimiz, hər bir müştərimiz üçün fərdi ehtiyacları nəzərə alaraq, mükəmməl həllər təqdim etmək və onların onlayn uğurunu təmin etməkdir.")}</p>
          </div>
        </div>

        <div className="mt-[64px] md:mt-[104px]">
          <div className="md:max-w-container max-w-containerSm mx-auto justify-between flex md:flex-row flex-col items-center gap-5">
            <div className="bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]">
              <p className="text-[32px] font-semibold">{t("7 illik")}</p>
              <p className="text-[18px]">{t("Təcrübə")}</p>
            </div>
            <div className="bg-[#FB8C00] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]">
              <p className="text-[32px] font-semibold">300 +</p>
              <p className="text-[18px]">{t("Hazırlanan veb sayt")}</p>
            </div>
            <div className="bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]">
              <p className="text-[32px] font-semibold">100 %</p>
              <p className="text-[18px]">{t("Zamanında təhvil")}</p>
            </div>
          </div>
        </div>

        <div className="max-w-containerSm md:max-w-container mx-auto mt-[64px] md:mt-[104px]">
          <div className="flex items-center justify-between">
            <h2 className="md:text-[32px] text-3xl font-medium">{t("Niyə biz ?")}</h2>
          </div>

          <div className="grid md:grid-cols-3 mt-10 gap-10">
            {opportunitiesData.map(({ id, image, title, description }) => (
              <div key={id} className="border-[1px] border-[#388E3C] rounded-[20px]">
                <img className="h-[194px] w-full rounded-t-[20px]" src={image} alt={title} loading="lazy" />
                <div className="px-5">
                  <h3 className="mt-[20px] text-2xl md:text-[24px] font-medium">{t(title)}</h3>
                  <p className="mt-[12px] mb-[20px]">{t(description)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
