import React from 'react';
import { logo, mail, phone, sosial1, sosial2, sosial3 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-white mt-16 md:mt-24">
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex justify-between py-12 md:py-20">
        {/* Logo and Social Media Links */}
        <div className="md:w-[254px]">
          <img className="w-[254px]" src={logo} alt={t("Firma Logosu")} />
          <p className="text-lg mt-5 md:mt-16">{t("Biznesinizi bizimlə inkişaf etdirin !")}</p>
          <div className="flex gap-3 mt-5 md:mt-16">
            <a href="https://api.whatsapp.com/send/?phone=994516834218&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
              <img className="w-10 h-10" src={sosial1} alt={t("WhatsApp İkonu")} />
            </a>
            <a href="https://www.instagram.com/prosayt.az" target="_blank" rel="noopener noreferrer">
              <img className="w-10 h-10" src={sosial2} alt={t("Instagram İkonu")} />
            </a>
            <a href="https://www.linkedin.com/company/prosaytmmc" target="_blank" rel="noopener noreferrer">
              <img className="w-10 h-10" src={sosial3} alt={t("LinkedIn İkonu")} />
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div className="md:w-[387px] mt-10 md:mt-0">
          <h3 className="text-2xl font-medium text-black">{t("Sürətli keçid")}</h3>
          <div className="mt-5 bg-[#E5E5E5] h-[1px]"></div>
          <ul className="space-y-2 md:space-y-4 mt-5">
            <li>
              <a href="/" className="hover:underline" aria-label={t("Ana səhifəyə gedin")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Ana səhifə")}
              </a>
            </li>
            <li>
              <a href="/why-us" className="hover:underline" aria-label={t("Niyə biz? sayfasına git")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Niyə biz?")}
              </a>
            </li>
            <li>
              <a href="/services" className="hover:underline" aria-label={t("Xidmətlərimiz sayfasına get")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Xidmətlərimiz")}
              </a>
            </li>
            <li>
              <a href="/portfolio" className="hover:underline" aria-label={t("Portfolio sayfasına get")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Portfolio")}
              </a>
            </li>
            <li>
              <a href="/faq" className="hover:underline" aria-label={t("FAQ sayfasına get")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("FAQ")}
              </a>
            </li>
            <li>
              <a href="/blogs" className="hover:underline" aria-label={t("Bloq sayfasına get")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Bloq")}
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:underline" aria-label={t("Əlaqə sayfasına get")}>
                <i className="fas fa-chevron-right text-black mr-3" />
                {t("Əlaqə")}
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="md:w-[387px] mt-10 md:mt-0">
          <h3 className="text-2xl font-medium text-black">{t("Əlaqə")}</h3>
          <div className="mt-5 bg-[#E5E5E5] h-[1px]"></div>
          <ul className="space-y-2 md:space-y-4 mt-5">
            <li>
              <a href="tel:+994516834218" className="hover:underline flex" aria-label={t("Telefon nömrəsinə zəng edin")}>
                <img className="h-6 w-6 mr-2" src={phone} alt={t("Telefon İkonu")} />
                {t("Əlaqə nömrəsi")}
              </a>
            </li>
            <li>+994 51 683 42 18</li>
            <li>
              <a href="mailto:info@prosayt.az" className="hover:underline flex" aria-label={t("E-poçt göndərin")}>
                <img className="h-6 w-6 mr-2" src={mail} alt={t("E-posta İkonu")} />
                {t("E-poçt")}
              </a>
            </li>
            <li>info@prosayt.az</li>
          </ul>
        </div>
      </div>

      <div className="bg-[#E5E5E5] h-[1px] max-w-containerSm md:max-w-container mx-auto"></div>

      <div className="max-w-containerSm md:max-w-container md:flex justify-between mx-auto py-4 text-sm">
        <p>Prosayt.az © {t("Bütün hüquqlar qorunur.")}</p>
        <a href="https://www.prosayt.az" target="_blank" rel="noopener noreferrer">
          <p>{t("Sayt hazırlandı")}: Prosayt.az</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
