import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel'
import About from '../../Components/home/About/About';
import Awards from '../../Components/home/Awards/Awards';
import Faq from '../../Components/home/Faq/Faq';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Contact from '../../Components/home/Contact/Contact';
import Services from '../../Components/home/Services/Services';
import Comments from '../../Components/home/Comments/Comments';
import Prioritet from '../../Components/home/Prioritet/Prioritet';
import Partners from '../../Components/home/Partners/Partners';
 


const Home = () => {
  return ( 
    <> 
    <Carousel/>
    <About/>
    <Awards/>
    <WhyUs/>
    <Services/>
    <Comments/>
    <Faq/>
    <Prioritet/>
    <Partners/>
    <Contact/>

    
    </>
  )
}

export default Home;
