import React from 'react';
import {
  partners1,
  partners2,
  partners3,
  partners4,
} from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const opportunitiesData = [
  { id: 1, image: '🌐', title: "Vebsaytların hazırlanması", description: "Profesional və sürətli vebsayt hazırlama xidmətləri" },
  { id: 2, image: '🖥️', title: "Domen və hostinq satışı", description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri" },
  { id: 3, image: '⚙️', title: "Google SEO", description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın" },
  { id: 4, image: '🛠️', title: "Vebsaytlara texniki dəstək", description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri" },
  { id: 5, image: '📧', title: "Korporativ poçt", description: "Güvənli və professional korporativ e-poçt həlləri" },
  { id: 6, image: '💳', title: "Ödəmə sistemlərinin inteqrasiyası", description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası" },
];

const Services = () => {
  const { t } = useTranslation();
  const partners = [partners1, partners2, partners3, partners4];

  return (
    <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
      {/* Services section */}
      <nav aria-label="Breadcrumb" className="mb-4">
        <p>{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`} /> {t("Xidmətlər")}</p>
      </nav>
      
      {/* Desktop View */}
      <section className="max-w-container mx-auto mt-[24px] md:mt-[56px] hidden md:block">
        <header>
          <h2 className="text-[32px] font-medium text-center mx-auto">{t("Xidmətlərimiz")}</h2>
        </header>
        
        <div className="grid grid-cols-3 mt-10 gap-10">
          {opportunitiesData.map((item) => (
            <div key={item.id} className="rounded-[20px] py-4 w-[400px] h-[220px] px-3 shadow bg-white">
              <div className="h-[50px] w-[50px] mx-auto text-4xl"> {item.image} </div>
              <div>
                <h3 className="mt-[12px] text-[22px] font-medium text-center">{t(item.title)}</h3>
                <p className="mt-[12px] mb-[32px] text-center">
                  {item.description.length > 80 ? item.description.slice(0, 80) + '...' : t(item.description)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Mobile View */}
      <section className="max-w-containerSm mx-auto mt-[64px] md:mt-[104px] block md:hidden">
        <h3 className="text-3xl font-medium text-center mb-8">{t("Xidmətlərimiz")}</h3>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {opportunitiesData.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="rounded-[20px] py-4 px-3 shadow bg-white">
                <div className="h-[50px] w-[50px] mx-auto text-4xl"> {slide.image} </div>
                <div>
                  <h4 className="mt-[12px] text-[22px] font-medium text-center">{t(slide.title)}</h4>
                  <p className="mt-[12px] mb-[32px] text-center">
                    {slide.description.length > 60 ? slide.description.slice(0, 60) + '...' : t(slide.description)}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <a href='/services'>
          <button className='h-[51px] w-full md:w-[164px] rounded-[12px] mt-8 bg-[#FB8C00] text-white md:block hidden'>{t("Hamısına bax")}</button>
        </a>
      </section>

      {/* Partners section */}
      <section className="md:max-w-container md:mt-[104px] mt-[64px] max-w-containerSm mx-auto">
        <h3 className="text-black text-3xl md:text-[32px] font-medium text-center mb-8 md:mb-0">{t("Partnyorlarımız")}</h3>
        <div className="block mt-[56px] md:gap-[124px] md:flex">
          {partners.map((flag, index) => (
            <div key={index}>
              <img
                className="h-[80px] mx-auto mt-8 md:mx-0 md:mt-0"
                src={flag}
                alt={`Partner logo ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Services;
