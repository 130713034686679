import React, { useMemo } from "react";
import {
  blogs1,
  blogs2,
  blogs3,
  blogs4,
  blogs5,
  blogs6,
} from "../../assets/images";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const blogs = [
  { id: 1, image: blogs1,  title: "Niyə Hər Biznesin Peşəkar Vebsaytı Olmalıdır?", description: "Hər biznesin rəqəmsal dünyada öz yerini tapması zəruridir. Bir vebsayt, müştərilərinizin sizi tanıması, məhsul və ya xidmətləriniz haqqında məlumat alması və sizinlə əlaqə qurması üçün ən mühüm vasitədir. Müasir dövrdə müştərilərin əksəriyyəti bir şirkəti ilk dəfə internet üzərindən kəşf edir. Buna görə də, peşəkar vebsayt müştərilərin ilk təəssüratını formalaşdırmaq üçün böyük əhəmiyyət daşıyır. Vebsaytlar potensial müştəriləri cəlb etmək, satışları artırmaq, şirkətinizin etibarını gücləndirmək və rəqiblərinizə qarşı üstünlük qazanmaq üçün effektiv vasitədir. SEO optimizasiyası, istifadəçi dostu interfeys və mobil uyğun dizaynla hazırlanan vebsaytlar, işinizin böyüməsinə töhfə verir. Bundan əlavə, sosial mediaya inteqrasiya olunmuş vebsaytlar, biznesin geniş miqyasda tanınmasına və müştəri bazasının artmasına yardım edir." },
  { id: 2, image: blogs2,  title: "2024-cü il üçün Vebsayt Dizayn Trendleri", description: "2024-cü ildə veb dizayn dünyasında bir neçə yeni trend ön plana çıxacaq. Minimalizm, artıq son illərdə populyar olsa da, bu il daha çox diqqət çəkəcək. Sadə, təmiz xətlər və boş sahələr daha intuitiv və göz oxşayan dizaynlar yaradır. Qaranlıq rejim də istifadəçilər tərəfindən geniş qəbul olunub və bu trend davam edəcək. Əlavə olaraq, mikro-animasiyalar və interaktiv elementlər istifadəçi təcrübəsini artırmaq üçün geniş istifadə olunacaq. Interaktiv elementlər istifadəçiləri cəlb etməklə yanaşı, onları vebsaytda daha çox saxlamağa kömək edir. Bu trendlərin yanında, video fonlar və 3D vizual elementlər də veb dizaynda populyarlaşacaq. UX/UI dizaynında isə əsas diqqət istifadənin rahatlığına və məlumatların daha sürətli əldə olunmasına yönələcək. Bütün bu trendler, vebsaytların həm estetik, həm də funksional olaraq istifadəçilər üçün daha cəlbedici olmasına imkan yaradacaq."},
  { id: 3, image: blogs3,  title: "SEO və Trafik Arttırmanın Yolları", description: "SEO (Axtarış Mühərriki Optimizasiyası), vebsaytınızın axtarış nəticələrində daha yüksək yerlərdə çıxmasına kömək edən vacib bir texnikadır. Bunun üçün, vebsaytınızdakı kontent uyğun açar sözlərlə optimizasiya edilməlidir. SEO-nun əsasları arasında doğru açar sözlərin seçimi, düzgün başlıqların və meta təsvirlərin yazılması, şəkillərin optimizasiyası və sürətli yükləmə vaxtları yer alır. Eyni zamanda, yüksək keyfiyyətli geri bağlantılar əldə etmək də SEO üçün çox vacibdir. SEO yalnız saytınızın Google kimi axtarış mühərriklərində daha yaxşı yerlərdə çıxmasına kömək etmir, həm də sayta gələn trafik həcmini artırır. Bloq paylaşımları ilə müntəzəm kontent yaratmaq və sosial mediada vebsaytınızı reklam etmək, sayta daha çox ziyarətçi cəlb etməyin effektiv yollarından biridir. Belə addımlar, zamanla sayta daha çox üzvi trafik cəlb etməyə və şirkətinizin onlayn mövcudluğunu gücləndirməyə kömək edəcək."},
  { id: 4, image: blogs4,  title: "Kiçik Bizneslər Üçün Ən Yaxşı Vebsayt Platformaları", description: "Kiçik bizneslər üçün effektiv və asan idarə olunan vebsayt platformaları seçmək çox vacibdir. Hazırda bazarda bir neçə populyar platforma var: WordPress, Wix, Squarespace və Shopify. WordPress, özəlləşdirilə bilən və SEO dostu platforma olması ilə diqqət çəkir, lakin daha çox texniki bilik tələb edə bilər. Wix və Squarespace daha çox istifadəçi dostu interfeysi ilə fərqlənir və kiçik biznes sahibləri üçün sadə sürüklə-burax redaktə alətləri təqdim edir. Shopify isə əsasən e-ticarət saytları üçün ideal platformadır. Hansı platformanı seçmək lazımdır sualı isə biznesinizin ehtiyaclarına bağlıdır. Sadə bir blog və ya vizit kartı saytı üçün Wix və ya Squarespace ideal seçim ola bilər, lakin daha geniş miqyaslı və SEO-yə yönəlmiş layihələr üçün WordPress tövsiyə olunur. E-ticarət üçün isə Shopify ən məntiqli seçimdir."},
  { id: 5, image: blogs5,  title: "Vebsayt Təhlükəsizliyi: Kibertəhlükələrdən Necə Qorunmalı?", description: "Vebsayt təhlükəsizliyi hər bir biznes üçün önəmli bir məsələyə çevrilib. Kibertəhlükələr hər keçən gün daha da artdığı üçün vebsaytınızı qorumaq vacibdir. İlk addım SSL sertifikatından istifadə etməkdir ki, bu da istifadəçilərin məlumatlarını şifrələyərək təhlükəsizliyini təmin edir. Bundan başqa, müntəzəm olaraq vebsaytınızı və serverləri güncəlləmək və zəiflikləri aradan qaldırmaq lazımdır. Güclü şifrələr və iki mərhələli doğrulama (2FA) sistemləri, hesablarınızın hacklənməsinin qarşısını almağa kömək edir. Ayrıca, təhlükəsizlik divarları və DDoS hücumlarına qarşı qoruyucu vasitələr vebsaytın sabitliyini təmin edir. Vebsaytın ehtiyat nüsxəsini müntəzəm olaraq almaq isə ən pis hallarda belə məlumatlarınızı geri qaytarmağa imkan verir. İstifadəçilərin şəxsi məlumatlarını qorumaq həm hüquqi məsuliyyət, həm də müştəri etibarını qazanmaq üçün çox vacibdir."},
  { id: 6, image: blogs6,  title: "Vebsaytınızın Yükləmə Sürətini Necə Artırmaq Olar?", description: "Vebsaytın yükləmə sürəti həm SEO, həm də istifadəçi təcrübəsi baxımından çox əhəmiyyətlidir. Sürətli yüklənən saytlar istifadəçilərin saytda qalma müddətini artırır və axtarış mühərriklərində daha yüksək yerlərə çıxmağınıza kömək edir. İlk addım şəkillərin ölçüsünü optimallaşdırmaqdır. JPEG və ya WEBP kimi sıxılmış formatlardan istifadə etmək, yükləmə müddətini qısaldır. Eyni zamanda, cache texnologiyaları, brauzer yaddaşında vebsayt elementlərinin saxlanılmasına imkan verir və gələcək ziyarətçilər üçün daha sürətli yüklənmə təmin edir. Lazımsız və böyük JavaScript və CSS fayllarını minimal şəkildə istifadə etmək də sürətə müsbət təsir edir. Ayrıca, CDN (Content Delivery Network) istifadə edərək, məlumatları coğrafi baxımdan istifadəçilərə daha yaxın serverlərdən göndərmək mümkündür. Sürətli bir vebsayt, istifadəçilərin daha çox vaxt keçirməsinə və daha az sayda tərk etməsinə səbəb olur, bu da vebsaytın performansına birbaşa təsir göstərir."},
];

const BlogsDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const activeBlog = useMemo(() => blogs.find((blog) => blog.id === parseInt(id)), [id]);

  if (!activeBlog) {
    return <div>{t("Blog not found")}</div>;
  }

  const { image, title, description } = activeBlog;

  return (
    <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
     <nav aria-label="Breadcrumb" className="mb-4">
        <p>{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`} /> {t("Bloq")}</p>
      </nav>
      <div>
        <div className="max-w-container mx-auto mt-[24px] md:mt-[56px] md:flex gap-8">
          <img
            className="md:w-[500px] md:h-[300px] w-full rounded-[9px]"
            src={image}
            alt="Blog"
          />
          <div className="flex-1 mt-8 md:mt-0">
            <p className="md:text-[32px] text-3xl font-medium md:leading-[50px]">{t(title)}</p>
            <p className="md:text-2xl text-xl mt-4 md:mt-5">{t(description)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsDetail;
