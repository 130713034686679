import React, { useState } from 'react';
import { globe } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Contact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientMessage, setClientMessage] = useState('');
  const [clientWish, setClientWish] = useState('');

  const handleSubmitContact = async (e) => {
    e.preventDefault();

    const inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage, wish: clientWish };

    try {
      await axios.post('https://www.prosayt.az/prosayt/contact.php/', inputs);
      
      toast.success('Form göndərildi');
      
      // Clear form after successful submission
      setClientName('');
      setClientEmail('');
      setClientPhone('');
      setClientMessage('');
      setClientWish('');
      
      navigate('/');
    } catch (error) {
      console.error('Sipariş gönderilirken bir hata oluştu:', error);
      toast.error('Form göndərilərkən xəta baş verdi');
    }
  };

  return (
    <div className="md:max-w-container max-w-containerSm mx-auto mt-[64px] md:mt-[104px]">
      <div className="flex flex-col-reverse md:flex-row justify-between">
        <div className="mt-8 md:mt-0 hidden md:flex justify-center items-center">
          <img
            className="w-[479px] h-[412px] md:auto rounded-3xl"
            src={globe}
            alt="Global contact or communication" // SEO için açıklayıcı alt metni ekledik
          />
        </div>
        <div className="md:w-[745px] md:h-[563px] md:mt-0 bg-[white] rounded-[24px] px-6 py-4 box-border">
          <h2 className="text-3xl md:text-[46px] font-medium md:mt-6">{t('Bir Sualınız Var?')}</h2>
          <p className="text-3xl md:text-[46px] font-medium mb-8 md:mb-0 md:mt-6">{t('Cavabımız Hazırdır')}</p>
          <form onSubmit={handleSubmitContact} className="md:mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="sr-only">{t('Ad, soyad *')}</label>
                <input
                  onChange={(event) => setClientName(event.target.value)}
                  value={clientName}
                  required
                  placeholder={t('Ad, soyad *')}
                  type="text"
                  id="name"
                  className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                />
              </div>

              <div>
                <label htmlFor="email" className="sr-only">{t('Email *')}</label>
                <input
                  onChange={(event) => setClientEmail(event.target.value)}
                  value={clientEmail}
                  required
                  placeholder={t('Email *')}
                  type="email"
                  id="email"
                  className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                />
              </div>

              <div>
                <label htmlFor="phone" className="sr-only">{t('Telefon *')}</label>
                <input
                  onChange={(event) => setClientPhone(event.target.value)}
                  value={clientPhone}
                  required
                  placeholder={t('Telefon *')}
                  type="number"
                  id="phone"
                  className="bg-[white] border border-neutral-200 mt-2 block w-full rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"
                />
              </div>
            </div>

            <div>
              <label htmlFor="message" className="sr-only">{t('Sizə necə kömək edə bilərik? *')}</label>
              <textarea
                id="message"
                rows="4"
                onChange={(event) => setClientWish(event.target.value)}
                value={clientWish}
                placeholder={t('Sizə necə kömək edə bilərik? *')}
                className="bg-[white] h-36 pl-3 pr-3 pt-3 block w-full border mt-6 border-[#dddddd] rounded-[5px] shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
              ></textarea>
            </div>

            <div className="flex justify-end">
              <button type="submit" className="w-[148px] h-10 p-2.5 bg-[#FB8C00] rounded-xl justify-center items-center mt-6 gap-2.5 inline-flex text-[white]">
                {t('Göndər')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

